export default {
    app: {
        drawerTitle: process.env.VUE_APP_DRAWER_TITLE,
        title: process.env.VUE_APP_TITLE,
        shortTitle: process.env.VUE_APP_SHORT_TITLE,
        icon: 'language',
        appKey: process.env.VUE_APP_KEY
    },

    gdpr_flag: false,

    user: {
        id: null,
        name: null,
        email: null,
        type: null,
        reset: null,
        access_token: null,
        last_seen: null
    },

    drawer: true,
    loader: false,
    progress: false,
    loaderText: '',
    toolbarTitle: '',
    search: '',
    searchFieldState: false,
    mobileSearch: false,

    snackbar: {
        display: false,
        y: 'bottom',
        x: 'left',
        mode: '',
        timeout: 7000,
        text: 'Hello, I\'m a snackbar'
    },

    access_log: [],
    currentRoute: null,

    userTypes: null,
    permitTypes: null,
    residenceTypes: null,
    entities: null,
    vehicleOwnershipTypes: null,
    applicationStatuses: null,
    paymentStatuses: null,
    permitStatuses: null,
    parkingLots: [],
    pillars: [],
    infoPogojiUporabe: false,
    infoGdpr: false,
    infoInstructions: false
    
}