import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import AppPageHeader from '@/components/layout/AppPageHeader'
import AppLoader from '@/components/Loader.vue'
import { Role } from '@/helpers/role'
import { getUserRole } from '@/helpers/utilities'
import VueFileAgent from 'vue-file-agent';
import 'vue-file-agent/dist/vue-file-agent.css';
import VueQRCodeComponent from 'vue-qrcode-component'
import GdprAccessLog from '@/components/GdprAccessLog.vue'
import PermitApplicationsDataTable from '@/components/PermitApplicationsDataTable'
import SetUserAccountData from '@/components/SetUserAccountData.vue'

//fusion charts
import VueFusionCharts from 'vue-fusioncharts';
import FusionCharts from 'fusioncharts';
import Column2D from 'fusioncharts/fusioncharts.charts';
import StackedBar2D from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import Chips from '@/components/Chips.vue';
import { VueReCaptcha } from 'vue-recaptcha-v3'
import './registerServiceWorker'

Vue.use(VueFusionCharts, FusionCharts, StackedBar2D, Column2D, FusionTheme);

Vue.use(VueFileAgent);

Vue.component('app-page-header', AppPageHeader);
Vue.component('app-loader', AppLoader)
Vue.component('qr-code', VueQRCodeComponent)
Vue.component('gdpr-access-log', GdprAccessLog)
Vue.component('permit-applications-data-table', PermitApplicationsDataTable);
Vue.component('chips', Chips);
Vue.component('set-user-account-data', SetUserAccountData);

Vue.use(PerfectScrollbar)

Vue.use(VueReCaptcha, { 
    //siteKey: '6Lc1hdcZAAAAAFlvsnMD2_-9Iz6xELJtTCd16uUn', // development - localhost sitekey
    siteKey: '6Le-i9cZAAAAAE9goG5M_MSh7WPpWB0Sr9zTZnBW', // production sitekey
    loaderOptions: {
      autoHideBadge: true
    }  
  }
);

var CryptoJS = require('crypto-js')

// Some middleware to help us ensure the user is authenticated.
router.beforeEach((to, from, next) => {

  const currentUser = store.getters.user;
  const authorize  = to.meta.authorize;

  //window.console.log("a")

  /*
  window.console.log("Current user: ")
  window.console.log(currentUser)

  window.console.log("Authorize: ")
  window.console.log(authorize);

  window.console.log("Path:")
  window.console.log(to.fullPath)
  */

  if(to.matched.some(record => record.meta.requiresAuth) && (!store.getters.user.access_token || store.getters.user.access_token === '')) {
    //window.console.log("b")

    return next({
      path: '/prijava',
      query: { redirect: to.fullPath }
    })
  } 

  /*else if(authorize && authorize !== 'undefined') {
    if(authorize.length && !authorize.includes(currentUser.type)) {
      window.console.log(2)
      return next({
        path: '/prijava',
        query: { redirect: to.fullPath }
      })
    }

    window.console.log(3)

    //return next()
  }*/

  else if ( (to.name == 'login' || to.name == 'passwordResetRequest' || to.name == 'passwordReset') && store.state.user.access_token) {
    //window.console.log("c")
    if(getUserRole() === Role.Admin && currentUser.type === Role.Admin) {
      //window.console.log("d")

        return next({name: 'admin_dashboard'})
    }

    return next({name: 'dashboard'})

    
  } else if(to.path == '/') {
    //window.console.log("e")
      return next()
  } else {
    //window.console.log("f")
      return next()
  }
  
})



// Check local storage to handle refreshes
if (window.localStorage) {
  var userToRemove = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_KEY_OLD)) || null

  if(userToRemove) {
    localStorage.removeItem(process.env.VUE_APP_KEY_OLD)
  }

  var user = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_KEY)) || null
  if (user) {
    var bytes  = CryptoJS.AES.decrypt(user, process.env.VUE_APP_CRYPTO_JS_KEY);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    user = decryptedData
    if( (store.state.user.id !== user.id) && (user.access_token && store.state.user.access_token !== user.access_token)) {
      store.commit('SET_USER', user)
    }
    
  }
}

//event bus
export const EventBus = new Vue();


Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
