import Vue from 'vue'
import VueRouter from 'vue-router'
import { Role } from '@/helpers/role'
import Login from '@/views/auth/Login.vue'
import HomePage from '@/views/public/HomePage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/prijava',
    name: 'login',
    component: Login
  },
  {
      path: '/odjava',
      name: 'logout',
      meta: { permissions: [] },
      component: () => import('@/views/auth/Logout.vue')
  },
  {
      path: '/ponastavitev-gesla',
      name: 'passwordResetRequest',
      component: () => import('@/views/auth/PasswordResetRequest.vue')
  },
  {
      path: '/ponastavitev-gesla/:token',
      name: 'passwordReset',
      component: () => import('@/views/auth/PasswordReset.vue')
  },    

  /* ERRORS */
  {
      path: '*',
      name: '404',
      component: () => import('@/views/errors/404.vue')
  },
  {
      path: '/403',
      name: '403',
      component: () => import('@/views/errors/403.vue')
  },
  {
      path: '/500',
      name: '500',
      component: () => import('@/views/errors/500.vue')
  },

  /** PAGES */
  {
    path: '/',
    name: 'home_page',
    component: HomePage
  },

  /** APPLICATIONS - VLOGE - PRVA STRAN */
  {
    path: '/parkirna-dovolilnica',
    name: 'parking_application',
    component: () => import('@/views/public/ParkingApplication.vue')
  },
  {
    path: '/blejska-promenada',
    name: 'promenade_application',
    component: () => import('@/views/public/PromenadeApplication.vue')
  },

  {
    path: '/',
    component: () => import('@/views/RootView.vue'),
    children: [
      /** ADMIN PAGES */
      {
        path: '/admin/22ehke0d/nadzorna-plosca',
        name: 'admin_dashboard' ,
        component: () => import('@/views/admin/Dashboard.vue'),
        meta: { requiresAuth: true, authorize: [Role.Admin] }
      },
      {
        path: '/admin/22ehke0d/nastavitve',
        name: 'admin_settings' ,
        component: () => import('@/views/admin/Settings.vue'),
        meta: { requiresAuth: true, authorize: [Role.Admin] }
      },
      {
        path: '/admin/22ehke0d/obvescanje/ustvari',
        name: 'admin_notifications' ,
        component: () => import('@/views/admin/AddNotification.vue'),
        meta: { requiresAuth: true, authorize: [Role.Admin] }
      },
      {
        path: '/admin/22ehke0d/obvescanje/:id',
        name: 'admin_notifications_edit' ,
        component: () => import('@/views/admin/EditNotification.vue'),
        meta: { requiresAuth: true, authorize: [Role.Admin] }
      },
      {
        path: '/admin/22ehke0d/obvescanje/seznam',
        name: 'admin_notifications_list' ,
        component: () => import('@/views/admin/Notifications.vue'),
        meta: { requiresAuth: true, authorize: [Role.Admin] }
      },
      {
        path: '/admin/22ehke0d/placila',
        name: 'admin_payments' ,
        component: () => import('@/views/admin/Payments.vue'),
        meta: { requiresAuth: true, authorize: [Role.Admin] }
      },
      {
        path: '/admin/22ehke0d/uporabniki',
        name: 'admin_users' ,
        component: () => import('@/views/admin/Users.vue'),
        meta: { requiresAuth: true, authorize: [Role.Admin] }
      },
      {
        path: '/admin/22ehke0d/uporabniki/:id',
        name: 'admin_users_edit' ,
        component: () => import('@/views/admin/EditUser.vue'),
        meta: { requiresAuth: true, authorize: [Role.Admin] }
      },
      {
        path: '/admin/22ehke0d/uporabniki/:id/dovolilnice/parkirna-dovolilnica',
        name: 'admin_user_parking_permit' ,
        component: () => import('@/views/admin/UserParkingPermit.vue'),
        meta: { requiresAuth: true, authorize: [Role.Admin] }
      },
      {
        path: '/admin/22ehke0d/uporabniki/:id/dovolilnice/dostop-promenada',
        name: 'admin_user_promenada_permit' ,
        component: () => import('@/views/admin/UserPromenadaPermit.vue'),
        meta: { requiresAuth: true, authorize: [Role.Admin] }
      },

      {
        path: '/admin/22ehke0d/dovolilnice',
        name: 'admin_permits' ,
        component: () => import('@/views/admin/Permits.vue'),
        meta: { requiresAuth: true, authorize: [Role.Admin] }
      },
      {
        path: '/admin/22ehke0d/dovolilnice/:id/uredi',
        name: 'admin_permits_edit' ,
        component: () => import('@/views/admin/EditPermit.vue'),
        meta: { requiresAuth: true, authorize: [Role.Admin] }
      },
      {
        path: '/admin/22ehke0d/dovolilnice/parkirna-dovolilnica',
        name: 'admin_create_parking_permit' ,
        component: () => import('@/views/admin/ParkingPermit.vue'),
        meta: { requiresAuth: true, authorize: [Role.Admin] }
      },
      {
        path: '/admin/22ehke0d/dovolilnice/dostop-promenada',
        name: 'admin_create_promenada_permit' ,
        component: () => import('@/views/admin/PromenadaPermit.vue'),
        meta: { requiresAuth: true, authorize: [Role.Admin] }
      },
      {
        path: '/admin/22ehke0d/vloge',
        name: 'admin_applications' ,
        component: () => import('@/views/admin/Applications.vue'),
        meta: { requiresAuth: true, authorize: [Role.Admin] }
      },
      {
        path: '/admin/22ehke0d/vloge/e-vloga/:id/uredi',
        name: 'admin_applications_edit' ,
        component: () => import('@/views/admin/EditEApplication.vue'),
        meta: { requiresAuth: true, authorize: [Role.Admin] }
      },

      // fizične vloge - create
      {
        path: '/admin/22ehke0d/vloge/parkirna-dovolilnca',
        name: 'admin_applications_parking_permit',
        component: () => import('@/views/admin/ParkingApplication.vue'),
        meta: { requiresAuth: true, authorize: [Role.Admin] }
      },

      //fizične vloge - edit
      {
        path: '/admin/22ehke0d/vloge/:id/uredi',
        name: 'admin_applications_edit2',
        component: () => import('@/views/admin/EditApplication.vue'),
        meta: { requiresAuth: true, authorize: [Role.Admin] }
      },

      {
        path: '/admin/22ehke0d/izvoz-podatkov',
        name: 'admin_exports' ,
        component: () => import('@/views/admin/Exports.vue'),
        meta: { requiresAuth: true, authorize: [Role.Admin] }
      },

      /** USER PAGES */
      {
        path: '/nadzorna-plosca',
        name: 'dashboard' ,
        component: () => import('@/views/user/Dashboard.vue'),
        meta: { requiresAuth: true, authorize: [Role.User] }
      },
      {
        path: '/vloge/parkirna-dovolilnica',
        name: 'user_parking_application' ,
        component: () => import('@/views/user/ParkingApplication.vue'),
        meta: { requiresAuth: true, authorize: [Role.User] }
      },
      {
        path: '/vloge/blejska-promenada',
        name: 'user_promenada_application' ,
        component: () => import('@/views/user/PromenadaApplication.vue'),
        meta: { requiresAuth: true, authorize: [Role.User] }
      },
      {
        path: '/dovolilnice/:id/zamenjava',
        name: 'user_permits_replacement' ,
        component: () => import('@/views/user/PermitReplacement.vue'),
        meta: { requiresAuth: true, authorize: [Role.User] }
      },
      {
        path: '/nastavitve',
        name: 'settings' ,
        component: () => import('@/views/user/Settings.vue'),
        meta: { requiresAuth: true, authorize: [Role.User] }
      }
    ]
  },
  /** DEV PAGES */
  {
    path: '/dev/films',
    name: 'dev_films',
    component: () => import('@/views/dev/Films.vue')
  },
  {
    path: '/dev/films/:id',
    name: 'edit_film',
    component: () => import('@/views/dev/Film.vue')
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: function(to, from, savedPosition) {
      return savedPosition || { x: 0, y: 0 }
  },
  linkActiveClass: 'v-list__tile--active router-link-active active',
  routes
})

export default router
