<template>
    <div>
        <v-layout row wrap :align-center="!wideLayout" :justify-center="!wideLayout" :fill-height="!wideLayout">
            <v-flex xs12 sm12 :md12="wideLayout" :lg12="wideLayout" :md10="!wideLayout" :lg10="!wideLayout" :xl8="!wideLayout">
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :items-per-page="5"
                    class="elevation-1"
                    :options.sync="options"
                    :loading="loading"
                    :search="search"
                    :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    itemsPerPageOptions: [5,10,15,25,50, 100]
                    }">

                    <template v-slot:top>
                        <v-toolbar class="elevation-1" color="white">
                            <v-toolbar-title class="font-weight-regular body-1">Pripadajoče vloge</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-flex xs4 md4 lg4 v-show="breakPoint">
                                <v-text-field class="pt-4"  label="Išči..." @keyup.esc="search = ''" v-model="search" append-icon="mdi-magnify" single-line clearable @click:clear="doSearch()"></v-text-field>
                            </v-flex>
                            <v-divider
                            class="mx-4"
                            inset
                            vertical
                            v-if="btnRefresh"
                            ></v-divider>
                            <v-tooltip v-if="btnRefresh" top>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="refreshDataTable" class="toolbar_btn_fab" elevation="0" color="secondary" v-on="on" fab x-small dark>
                                        <v-icon>mdi-sync</v-icon>
                                    </v-btn>
                                </template>
                                <span>Osveži podatke</span>
                            </v-tooltip>
                        </v-toolbar>
                        <br>
                    </template>
                    <template v-slot:item.action="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <!--<v-icon
                                id="dt_btn_edit"
                                color="success"
                                small
                                class="mr-2"
                                @click="editItem(item)"
                                v-on="on"
                            >
                                mdi-pencil
                            </v-icon>-->
                            <a :href="editItemUrl(item)" style="text-decoration:none;">
                                <v-icon
                                id="dt_btn_edit"
                                color="success"
                                small
                                class="mr-2"
                                v-on="on"
                                >
                                
                                mdi-pencil
                                </v-icon>
                            </a>
                        </template>
                        <span>Uredi</span>
                    </v-tooltip>
                    <v-tooltip>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                id="dt_btn_delete"
                                color="error"
                                small
                                class="mr-2"
                                @click="deleteItem(item)"
                                v-on="on"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Izbriši</span>
                    </v-tooltip>
                </template>
                <template v-slot:progress>
                    <v-progress-linear absolute  indeterminate :height="progressHeight()"></v-progress-linear>
                </template>
                <template slot="no-data">
                    <div class="no_data">{{ (this.loading) ? "Obdelava podatkov je v teku ..." : "Ni podatkov za prikaz." }}</div>
                </template>
                <template slot="no-results">
                    <div class="no_data">Ni podatkov za prikaz.</div>
                </template>
                <template slot="loading">
                    <div class="no_data">Obdelava podatkov je v teku ...</div>
                </template>
                <template v-slot:item.application_status="{ item }">
                    <v-chip label v-if="item.application_status !== null && item.application_status !=='null' && item.application_status !== ''" :color="getApplicationStatusColor(item.application_status)" dark small>{{ item.application_status.name }}</v-chip>
                    <span v-else>{{ item.application_status.name }}</span>
                </template>
                <template v-slot:item.payment_status="{ item }">
                    <v-chip label v-if="item.application_status !== null && item.payment_status !=='null' && item.payment_status !== ''" :color="getPaymentStatusColor(item.payment_status)" dark small>{{ item.payment_status.name }}</v-chip>
                    <span v-else>{{ item.payment_status.name }}</span>
                </template>
                <template v-slot:item.digital="{ item }">
                    <v-chip label :color="getMediaTypeColor(item.digital)" dark small>{{ getMediaTypeName(item.digital) }}</v-chip>
                </template>
                </v-data-table>
            </v-flex>
        </v-layout>
        
        <!-- delete dialog -->
        <v-dialog v-model="deleteDialog" persistent max-width="540">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <span>
                        <v-icon text>mdi-delete</v-icon>
                        {{ deleteItemText() }}
                    </span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click.native="dialogCancel()">Prekliči</v-btn>
                    <v-btn text :loading="loading" color="success" @click.native="dialogConfirm()">Nadaljuj</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { post, get, destroy, API_ENDPOINT } from '@/helpers/api'
import { EventBus } from '@/main.js'

export default {
    name: 'permit-applications-data-table',
    props: ['permitUuid','boxedLayout', 'settings'],

    data: () => ({
        headers: [
            { text: 'Št. vloge', value: 'id' },
            { text: 'Datum vpisa', value: 'created_at_date' },
            { text: 'Čas vpisa', value: 'created_at_time' },
            { text: 'Status vloge', value: 'application_status' },
            { text: 'Status plačila', value: 'payment_status'},
            { text: "Medij", value: "digital", visibility: true},
            { text: 'Upravljanje', value: 'action', sortable: false, align: "right" },
        ],
        index: 0,
        options: {},
        loading: false,
        search: '',
        interval : null,
        deleteDialog: false,
        itemToDelete: null,
        dialogConfirmation: false,
        editItemRouteName: 'admin_applications_edit',
        eventBusChannel: 'permit_applications_datatable',
        items: [],
        searchTimer:null

    }),

    watch: {
        options: {
            handler () {
                //this.items = [];
                this.totalItems = this.items.length;
            },
            deep: true,
        },

        search(value) {

        },

        searchFromStore(newValue) {
            this.search = newValue
            this.doSearch()
        }
    },

    computed: {
        wideLayout() {
           if(this.boxedLayout !== undefined) {
               if(this.boxedLayout === true) {
                   return false
               }

               return true
           }

           return true
       },

       breakPoint() {
           if(this.$vuetify.breakpoint.mdAndUp) {
                return true;
            }

             return false;
       },

       btnRefresh() {
           return true
       },

       searchFromStore: {
           get() {
               return this.$store.getters.search
           },

           set(value) {
               this.$store.commit('SET_SEARCH', this.search)
           }
       },
       
    },

    methods: {
        progressHeight() {
           return 3
       },

        getApplications(uuid) {
            var vm = this
            vm.loading = true
            get(API_ENDPOINT + 'v1/admin/permits/' + uuid + '/applications')
            .then(response => {
                //window.console.log(response)
                vm.items = []
                vm.imageItems = []
                response.data.forEach(item => {
                    vm.items.push(item)
                    if(item.file_type != 'application/pdf') {
                        vm.imageItems.push(item)
                    }
                })
            })
            .catch(error => {
                window.console.error("### ERROR ApplicationFilesDataTable ###")
                window.console.log(error)
            })
            .then(() => {
                vm.loading = false
            })
        },

        refreshDataTable() {
            this.getApplications(this.permitUuid);
        },

        editItem(item) {
            //console.log(item)
            this.$router.push({
                name: this.editItemRouteName,
                params: {
                    id: item.uuid
                }
            })
        },

        editItemUrl(item) {
          // window.console.log(item)
           let itemLocation = ""
               let location = "";
                if(item.digital == 1) {
                    location = this.$router.resolve({name: this.editItemRouteName, params: {id:item.uuid }})
                } else {
                    let routeUrl = this.editItemRouteName + "2" //če je fizična vloga
                    location = this.$router.resolve({name: routeUrl, params: {id:item.uuid }})
                }

                itemLocation = window.location.origin + location.href;

           return itemLocation

       },

        deleteItem(item) {
            this.itemToDelete = item;
            this.deleteDialog = true
        },

        getApplicationStatusColor(application_status) {
           //window.console.log("getApplicationStatusColor");
           //window.console.log(application_status)
           if(application_status.type == 1) {
               return 'warning'
           }

           if(application_status.type == 2) {
                return '#546E7A'
           }

           if(application_status.type == 3) {
                return 'green'
           }

           if(application_status.type == 4) {
            return 'red'
           }
       },

       getPaymentStatusColor(payment_status) {
           if(payment_status.type == 1) {
               return 'red'
           }

           return 'green'
       },

       getMediaTypeName(digital) {
           if(digital == 1) {
               return "E-vloga"
           }

           return "Vloga"
       },

       getMediaTypeColor(digital) {
           if(digital == 1) {
               return "cyan lighten-1"
           }

           return "brown lighten-1"
       },

        deleteItemText() {
            if(this.itemToDelete != null) {
                return "Ste prepričani, da želite odstraniti vlogo s številko  #" + this.itemToDelete.id + " ?";
            }

            return ""
        },

        dialogConfirm() {
            var vm = this
            vm.loading = true
            var file_app_increment = vm.itemToDelete.app_increment;

            post(API_ENDPOINT + 'v1/admin/applications/' + vm.itemToDelete.uuid + '/delete')
            .then(response => {
                //window.console.log(response)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TEXT', "Vloga s številko #" + vm.itemToDelete.id +" je bila uspešno odstranjena.")
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
            })
            .catch(error => {
                window.console.error(error)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TEXT', "Pri odstranjevanju vloge s številko #" + vm.itemToDelete.id + " je prišlo do napake. Poskusite ponovno.")
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
            })
            .then(() => {
                vm.loading = false
                vm.deleteDialog = false
                vm.itemToDelete = null
                vm.getApplications(vm.permitUuid)
            })

            //window.console.log(this.itemToDelete)

        },

        dialogCancel() {
            this.dialogConfirmation = false
            this.deleteDialog = false
            this.itemToDelete = null
        },

        doSearch() {
           let vm = this
           if(this.searchTimer) {
               clearTimeout(this.searchTimer)
               this.searchTimer = null
           }

           this.searchTimer = setTimeout(() => {
               vm.getDataFromApi()
                .then(data => {
                   vm.items = data.items
                   vm.totalItems = data.totalItems
               })
               .catch(error => {
                   vm.items = [];
                   vm.totalItems = 0;
                   window.console.log(error)
               })
           }, 600)
       }
    },

    created() {

    },

    mounted() {
        var vm = this
        setTimeout(() =>{
            vm.getApplications(vm.permitUuid)
        }, 700)

        this.interval = setInterval(() => {
            vm.getApplications(vm.permitUuid)
        }, process.env.VUE_APP_DATA_TABLES_REFRESH_INTERVAL || 180000)

        EventBus.$on(this.eventBusChannel, (data) => {
            vm.getApplications(vm.permitUuid);
        })

    }, 

    beforeDestroy() {
        EventBus.$off(this.eventBusChannel)
    },

    destroyed() {
        clearInterval(this.interval)
    }
}

</script>

<style scoped>
    .v-data-table__empty-wrapper {
        text-align:center !important;
        color: #000 !important;
    }

    .no_data {
        text-align:center !important;
    }

    #style-11::-webkit-scrollbar {
        width: 10px;
        background-color: #F5F5F5;
    }
    
    #style-11::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(0,0,0,0.1);
        border: 1px solid #ccc;
    }

    #style-11::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: linear-gradient(left, #fff, #e4e4e4);
        border: 1px solid #aaa;
    }

    #style-11::-webkit-scrollbar-thumb:hover {
        background: #fff;
    }

    #style-11::-webkit-scrollbar-thumb:active {
        background: linear-gradient(left, #22ADD4, #1E98BA);
    }

    .toolbar_btn_fab {
        margin-right:10px;
    }

    #dt_btn_edit {
        font-size:18px !important;
    }

    #dt_btn_delete {
        font-size:18px !important;
    }

    .no_data {
        color: #000 !important;
    }

    .v-card__text {
        color: rgba(0,0,0,0.90) !important;
    }
</style>