<template>
    <v-app id="inspire" style="background: #fafafa;">
        <app-bar-front-page></app-bar-front-page>
        <v-main>
            <app-loader></app-loader>
            <v-container class="front_page_container" grid-list-xl>
                <v-layout row wrap align-center justify-center fill-height>
                    <v-flex xs12 sm12 md10 lg8 xl8>
                        <p class="title text-center"><v-icon large left>mdi-file-document-box-check-outline</v-icon> Elektronska oddaja vlog za izdajo dovolilnic</p>
                        <div class="layout column align-center">
                            <div>
                                <p class="title"> Občina Bled</p>
                            </div>
                            <img :src="logo" alt="Dovolilnice | Občina bled"  height="auto">
                            <br>
                        </div>
                    </v-flex>
                </v-layout>

                <v-layout row wrap align-center justify-center fill-height>
                    <v-flex xs12 sm12 md10 lg8 xl8>
                        <v-card>
                            <v-card-title>
                                <span class="subtitle-1"><v-icon left>mdi-account-multiple-check</v-icon> <strong>Že imate Blejsko kartico?</strong> Prijavite se tukaj in oddajte vlogo za podaljšanje.</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                                <p class="body-2 font-weight-regular card--text">
                                    V kolikor že imate Blejsko kartico lahko oddate vlogo za zamenjavo ali vlogo za novo dovolilnico v elektronski obliki s tem, da kliknete na spodnji gumb.
                                </p>
                                <p class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-information</v-icon> Če se v sistem prijavljate prvič, <strong>kot uporabniško ime uporabite številko Blejske kartice</strong>, ki je zapisana na kartici, <strong>kot geslo pa vašo registrsko številko za katerega velja kartica, brez presledkov in vezaja</strong>.</p>
                                <br>
                                <v-btn
                                class="card--text--btn"
                                color="secondary"
                                dark
                                @click="goToLogin()"
                                medium
                                block
                                style="margin-bottom:10px;"
                                 >
                                 <v-icon small left>mdi-login</v-icon>
                                Prijava
                                </v-btn>

                                <div class="div__divider__10" v-if="$vuetify.breakpoint.xsOnly"></div>

                                <v-btn
                                class="card--text--btn"
                                color="secondary"
                                dark
                                @click="infoNavodila()"
                                medium
                                block
                                style="margin-bottom:10px;"
                                 >
                                 <v-icon small left>mdi-information-variant</v-icon>
                                Navodila za uporabo
                                </v-btn>  
                            </v-card-text>

                            <v-card-text v-else>
                                <p class="body-2 font-weight-regular card--text">
                                    V kolikor že imate Blejsko kartico lahko oddate vlogo za zamenjavo ali vlogo za novo dovolilnico v elektronski obliki s tem, da kliknete na spodnji gumb.
                                </p>
                                <p class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-information</v-icon> Če se v sistem prijavljate prvič, <strong>kot uporabniško ime uporabite številko Blejske kartice</strong>, ki je zapisana na kartici, <strong>kot geslo pa vašo registrsko številko za katerega velja kartica, brez presledkov in vezaja</strong>.</p>
                                <v-btn
                                class="card--text--btn"
                                color="secondary"
                                dark
                                @click="goToLogin()"
                                small
                                style="margin-right:10px;"
                                 >
                                 <v-icon small left>mdi-login</v-icon>
                                Prijava
                                </v-btn>

                                <div class="div__divider__10" v-if="$vuetify.breakpoint.xsOnly"></div>

                                <v-btn
                                class="card--text--btn"
                                color="secondary"
                                dark
                                @click="infoNavodila()"
                                small
                                 >
                                 <v-icon small left>mdi-information-variant</v-icon>
                                Navodila za uporabo
                                </v-btn>  
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>

                <v-layout row wrap align-center justify-center fill-height>
                    <v-flex xs12 sm12 md5 lg4 xl4>
                        <v-card>
                            <v-img
                            :src="imgParking"
                            height="300px"
                            ></v-img>
                            <v-card-title>
                                <span class="subtitle-1"><v-icon left>mdi-parking</v-icon> Vloga za izdajo parkirne dovolilnice (Blejska kartica)</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                                <p class="body-2 font-weight-regular card--text">
                                    Če še nimate Blejske kartice, lahko tukaj oddate vlogo za izdajo Blejske kartice. Stroške dovolilnice boste poravnali preko spletne banke. Kartico, ki jo boste lahko uporabljali na parkomatih in javnih sanitarijah vam bomo poslali po pošti.
                                </p>     
                                
                                <br>
                                <v-btn
                                class="card--text--btn"
                                color="secondary"
                                dark
                                @click="goToParkingApplication()"
                                medium
                                block
                                style="margin-bottom:10px;"
                                 >
                                 <v-icon small left>mdi-arrow-right</v-icon>
                                Izpolni vlogo
                                </v-btn>                           
                            </v-card-text>
                            <v-card-text v-else>
                                <p class="body-2 font-weight-regular card--text">
                                    Če še nimate Blejske kartice, lahko tukaj oddate vlogo za izdajo Blejske kartice. Stroške dovolilnice boste poravnali preko spletne banke. Kartico, ki jo boste lahko uporabljali na parkomatih in javnih sanitarijah vam bomo poslali po pošti.
                                </p>     

                                <v-btn
                                class="card--text--btn"
                                color="secondary"
                                dark
                                @click="goToParkingApplication()"
                                small
                                 >
                                 <v-icon small left>mdi-arrow-right</v-icon>
                                Izpolni vlogo
                                </v-btn>                           
                            </v-card-text>
                        </v-card>
                    </v-flex>
                    <v-flex xs12 sm12 md5 lg4 xl4>
                        <v-card>
                            <v-img
                            :src="imgPromenada"
                            height="300px"
                            ></v-img>
                            <v-card-title>
                                <span class="subtitle-1"><v-icon left>mdi-city-variant-outline</v-icon> Vloga za izdajo dovolilnice za dostop na Blejsko promenado</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                                <p class="body-2 font-weight-regular card--text">
                                    Če želite imeti dostop do Blejske promenade, lahko tukaj oddate vlogo za izdajo dovolilnice za dostop do Blejske promenade. Do območja za pešce boste dostopali s klicem na telefonske številke posameznih potopnih stebričkov.
                                </p>

                                <v-btn
                                class="card--text--btn"
                                color="secondary"
                                dark
                                @click="goToPromenadeApplication()"
                                medium
                                block
                                style="margin-bottom:10px;"
                                 >
                                 <v-icon small left>mdi-arrow-right</v-icon>
                                Izpolni vlogo
                                </v-btn>
                            </v-card-text>
                            <v-card-text v-else>
                                <p class="body-2 font-weight-regular card--text">
                                    Če želite imeti dostop do Blejske promenade, lahko tukaj oddate vlogo za izdajo dovolilnice za dostop do Blejske promenade. Do območja za pešce boste dostopali s klicem na telefonske številke posameznih potopnih stebričkov.
                                </p>

                                <v-btn
                                class="card--text--btn"
                                color="secondary"
                                dark
                                @click="goToPromenadeApplication()"
                                small
                                 >
                                 <v-icon small left>mdi-arrow-right</v-icon>
                                Izpolni vlogo
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
               

            </v-container>
        </v-main>
        <app-fab></app-fab>
    </v-app>
</template>

<script>
import AppFab from '@/components/layout/AppFab'
import router from '@/router'
import  { Role } from '@/helpers/role'
import AppBarFrontPage from '@/components/layout/AppBarFrontPage'
import AppToolbar from '@/components/layout/AppToolbar'
import imgCardParking from '@/assets/card_blejska_kartica.png'
import imgCardPromenada from '@/assets/card_promenada.jpg'
import { getUserRole } from '@/helpers/utilities'

export default {
    components: {
        AppFab,
        AppBarFrontPage
    },

    data: () => ({
        logo: "https://cloud.conforma.si/public/img/obcina-bled/grb_bled_135_135.png"
    }),

    computed: {
        imgParking() {
            return imgCardParking
        },

        imgPromenada() {
            return imgCardPromenada
        }
    },

    methods: {
        goToParkingApplication() {

            if(getUserRole() === Role.User) {
                this.$router.push({
                    name: 'user_parking_application'
                })

                return;
            }

            this.$router.push({
                name: 'parking_application'
            })
        },

        goToPromenadeApplication() {
            if(getUserRole() === Role.User) {
                this.$router.push({
                    name: 'user_promenada_application'
                })

                return;
            }

            this.$router.push({
                name: 'promenade_application'
            })
        },

        goToLogin() {
            let currentUser = this.$store.getters.user
            if(getUserRole() === Role.Admin && currentUser.type === Role.Admin) {
                this.$router.push({name: 'admin_dashboard'})
            }

            else if(getUserRole() == Role.User && currentUser.type === Role.User) {
                this.$router.push({
                name: 'dashboard'})
            } else {
                this.$router.push({
                    name: 'login'
                })
            }

            return;
            
        },

        infoNavodila() {
            this.$store.commit('SET_INFO_INSTRUCTIONS',1)
        },

    },

    created() {
        
    }
}
</script>

<style scoped>
    #inspire{
        background-image: url("../../assets/bckpattern.png") !important;
        background-repeat: repeat !important;
    }

    .front_page_container {
        margin-top:30px;
        margin-bottom: 50px;
    }

    

    .card--text {
        color: rgb(0,0,0,0.8) !important;
    }

    .card--text--info {
        color: rgb(25, 118, 210, 0.8) !important;
    }

    .p--icon {
        margin-bottom: 2px !important;
    }

    .title {
        font-size:1.50rem !important;
    }

    .card--text--btn {
        opacity: 0.85
    }
</style>