import { post, get, destroy, download, API_ENDPOINT, DEMO_API_ENDPOINT } from '.././helpers/api'

export default {
    LOGIN (context, credentials) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'auth/login', credentials) 
                .then(response => {
                    context.commit('SET_USER', response.data) 
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    LOGOUT (context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'auth/logout')
            .then(response => {
                context.commit('SET_USER', null)
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PASSWORD_RESET_REQUEST (context, email) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'password/create', email)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PASSWORD_RESET (context, credentials) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'password/reset', credentials)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PASSWORD_RESET_TOKEN_FIND(context, token) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'password/find/' + token)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    ACCESS_LOG (context, params) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'access-log', params)
            .then(response => {
                resolve(response)
            })
            .catch(error =>{
                reject(error)
            })
        })
    },

    ROUTE_NAME (context, routeName) {
        context.commit('SET_CURRENT_ROUTE', routeName)
    },

    CONVERT_RFID_NR (context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'v1/admin/data/rfid-nr', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    ACCOUNT_EMAIL_UPDATE (context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'v1/services/email/update', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    ACCOUNT_PASSWORD_UPDATE (context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'v1/services/password/update', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    ADMIN_USER_ACCOUNT_PASSWORD_UPDATE (context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'v1/services/password/user/update', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    RFID_NR_SEARCH (context, nr) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'v1/admin/data/rfid-nr/' + nr)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    /** DEV */
    DELETE_USER (context, uuid) {
        return new Promise((resolve, reject) => {
            destroy(API_ENDPOINT + 'v1/admin/users/' + uuid + '/delete')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    RESOURCES(context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'v1/resources')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    ADMIN_RESOURCES(context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'v1/admin/resources')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    EDIT_USER(context, uuid) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'v1/admin/users/' + uuid)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    EDIT_APPLICATION(context, uuid) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'v1/admin/applications/' + uuid)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    DELETE_APPLICATION (context, uuid) {
        return new Promise((resolve, reject) => {
            destroy(API_ENDPOINT + 'v1/admin/applications/' + uuid + '/delete')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    CREATE_PARKING_PERMIT_FROM_APPLICATION (context, data) { //naredi dovolilnico iz oddane vloge
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'v1/admin/permits/parking/application/create', data)
            .then(response => {
                resolve(response)
            })
            .catch(error =>{
                reject(error)
            })
        })
    },

    CREATE_PARKING_PERMIT (context, data) { //naredi novo dovolilnico (admin)
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'v1/admin/permits/parking/create', data)
            .then(response => {
                resolve(response)
            })
            .catch(error =>{
                reject(error)
            })
        })
    },



    CREATE_PROMENADA_PERMIT_FROM_APPLICATION (context, data) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'v1/admin/permits/promenada/application/create', data)
            .then(response => {
                resolve(response)
            })
            .catch(error =>{
                reject(error)
            })
        })
    },

    CREATE_PROMENADA_PERMIT (context, data) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'v1/admin/permits/promenada/create', data)
            .then(response => {
                resolve(response)
            })
            .catch(error =>{
                reject(error)
            })
        })
    },

    EDIT_PERMIT(context, uuid) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'v1/admin/permits/' + uuid)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    DELETE_PERMIT (context, uuid) {
        return new Promise((resolve, reject) => {
            destroy(API_ENDPOINT + 'v1/admin/permits/' + uuid + '/delete')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    USER_EDIT_PERMIT(context, uuid) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'v1/user/permits/' + uuid)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    USER_APPLICATIONS_RESOURCES(context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'v1/user/applications/resources')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    USER_PROFILE_UPDATE (context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'v1/admin/users/' + data.uuid + '/profile/update', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    GET_EMAIL_NOTIFICATION_SETTINGS (context) {
        return new Promise ((resolve, reject) => {
            get(API_ENDPOINT + 'v1/admin/settings/email-notifications')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })  
    },

    STORE_EMAIL_NOTIFICATION_SETTINGS (context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'v1/admin/settings/email-notifications', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })  
    },

    CREATE_NOTIFICATION(context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'v1/admin/notifications/create', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        }) 
    },

    EDIT_NOTIFICATION(context, uuid) {
        return new Promise ((resolve, reject) => {
            get(API_ENDPOINT + 'v1/admin/notifications/' + uuid)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    UPDATE_NOTIFICATION(context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'v1/admin/notifications/' + data.notification_uuid + '/update', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        }) 
    },

    DELETE_NOTIFICATION(context, uuid) {
        return new Promise((resolve, reject) => {
            destroy(API_ENDPOINT + 'v1/admin/notifications/' + uuid + '/delete')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    CREATE_GDPR_ACCESS_LOG(context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'v1/services/gdpr-access-log', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        }) 
    },

    CHART_DATA(context) {
        return new Promise ((resolve, reject) => {
            get(API_ENDPOINT + 'v1/admin/data/charts')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    UPDATE_USER_ACCOUNT_DATA(context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'v1/services/user-account-data/update', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        }) 
    },

    SEND_INVOICES(context, paymentUuid) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'v1/services/payments/' + paymentUuid + '/send-invoices')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        }) 
    },

    REJECT_APPLICATION_EMAIL(context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'v1/services/notifications/email/reject-application', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })    
    },

    PROMENADA_PERMIT_STATUS_NOTIFICATION_EMAIL(context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'v1/services/notifications/email/promenada-permit-status', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        }) 
    },

    PARKING_APPLICATION_PRICE(context, applicationId) { // pridobi (izračuna) podatke (ceno) za posamezno vlogo
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'v1/admin/applications/parking/' + applicationId + '/price', applicationId)
            .then(response => {
                resolve(response)
            })
            .catch(error =>{
                reject(error)
            })
        })
    },

    PARKING_APPLICATION_GENERATE_INVOICE(context, applicationId) { // pridobi (izračuna) podatke (ceno) za posamezno vlogo
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'v1/admin/applications/parking/' + applicationId + '/invoice/generate')
            .then(response => {
                resolve(response)
            })
            .catch(error =>{
                reject(error)
            })
        })
    },

    REPLACE_PARKING_APPLICATION(context, data) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'v1/admin/applications/office/parking/replace', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    DOWNLOAD_FILE(context, uuid) {
        return new Promise( (resolve, reject) => {
            download(API_ENDPOINT + 'v1/admin/exports/'+ uuid + '/download')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    DELETE_FILE(context, uuid) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'v1/admin/exports/'+ uuid + '/delete')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    EXPORT(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'v1/admin/exports/' + data.group, data.queryParameters)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

}