import { Role } from '@/helpers/role'

var CryptoJS = require('crypto-js')

const randomElement = (arr = []) => {
    return arr[Math.floor(Math.random() * arr.length)];
  };
  
  const kebab =  (str) => {
    return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  };
  
  const toggleFullScreen = () => {
    let doc = window.document;
    let docEl = doc.documentElement;
  
    let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
    let cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
  
    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      requestFullScreen.call(docEl);
    }
    else {
      cancelFullScreen.call(doc);
    }
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  export function getCurrentDate() {
      let dt = new Date()
      let currentDate = dt.getFullYear() + '-' + (parseInt(dt.getUTCMonth()) + 1) + '-' + dt.getDate()

      return currentDate
  }

  export function currentDate() {
      let dt = new Date();

      return [
        dt.getFullYear(),
        padTo2Digits(dt.getMonth() + 1),
        padTo2Digits(dt.getDate()),
      ].join('-');
  }

  export function oneYearLater() {
      let dt = new Date();
      dt.setMonth(new Date().getMonth() + 12)

      return [
        dt.getFullYear(),
        padTo2Digits(dt.getMonth() + 1),
        padTo2Digits(dt.getDate()),
      ].join('-');
  }

  export function getCurrentTime() {
      let dt = new Date()
      return dt.getHours() + ":" + String(dt.getMinutes()).padStart(2, "0")
  }

  export function formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
  }

  export function getUserSettingsFromStorage(key) {
    var userSettings = JSON.parse(window.localStorage.getItem(key)) || null
    if(userSettings !== null) {
      var bytes  = CryptoJS.AES.decrypt(userSettings, process.env.VUE_APP_CRYPTO_JS_KEY);
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      userSettings = decryptedData
    }
    
    //window.console.log(userSettings)

    return userSettings
  }


  export function getUserRole() {
    var user = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_KEY)) || null
    var userRole = null;
    
    if(user == null || user == "" || user == undefined) {
      return null
    }

    var bytes  = CryptoJS.AES.decrypt(user, process.env.VUE_APP_CRYPTO_JS_KEY);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    user = decryptedData

    if(user.type === Role.Admin) {
      userRole = Role.Admin
    } else if(user.type === Role.User) {
      userRole = Role.User
    } else {
      userRole = null
    }

    //window.console.log(user)
    //window.console.log(user.type)

    return userRole

  }

  export function isAdmin() {
    var user = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_KEY)) || null
    var userRole = null;
    
    if(user == null || user == "" || user == undefined) {
      return null
    }

    var bytes  = CryptoJS.AES.decrypt(user, process.env.VUE_APP_CRYPTO_JS_KEY);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    user = decryptedData

    if(user.type === Role.Admin) {
      return true;
    } else if(user.type === Role.User) {
      return false;
    } else {
      return null;
    }

  }

  export function storeUserSettingsToLocalStorage(key, data) {
    var userSettings = JSON.parse(window.localStorage.getItem(key)) || null

    if(userSettings == null) {
      userSettings = window.localStorage.setItem(key, JSON.stringify(data))
    }
    
    for(var property in data) {
      userSettings[property] = data[property]
    }

    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(userSettings), process.env.VUE_APP_CRYPTO_JS_KEY).toString();
    //window.localStorage.setItem(key, JSON.stringify(userSettings))
    window.localStorage.setItem(key, JSON.stringify(ciphertext))
    window.console.log(userSettings)
    window.console.log(ciphertext)

    return userSettings
  }

  export function create_UUID(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });

    return uuid;
  }

  export function randomString(length) {
    var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    var string_length = length;
    var randomstring = '';
    for (var i=0; i<string_length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum,rnum+1);
    }
    return randomstring
  }

  export function random5CharsTag() {
    return (+new Date).toString(36).slice(-5);
  }

  export function randomTagStringGenerator(len, an) {
    an = an && an.toLowerCase();
    var str = "",
      i = 0,
      min = an == "a" ? 10 : 0,
      max = an == "n" ? 10 : 62;
    for (; i++ < len;) {
      var r = Math.random() * (max - min) + min << 0;
      str += String.fromCharCode(r += r > 9 ? r < 36 ? 55 : 61 : 48);
    }
    return str;

    //console.log(randomTagStringGenerator(10));      // i.e: "4Z8iNQag9v"
    //console.log(randomTagStringGenerator(10, "a")); // i.e: "aUkZuHNcWw"
    //console.log(randomTagStringGenerator(10, "n")); // i.e: "9055739230"
  }

  export function isMobileDevice(obj) {    
    if(obj.$vuetify.breakpoint.mdAndDown) {
      return true;
    }

    return false;
  }

  export function demoApiEndpoint() {
    return "http://demo-api.test/api/"
  }

  export function applicationMediaTypeColor(mediaType) {
    if(mediaType === 'digital') {
      return process.env.VUE_APP_PARKING_EAPPLICATION_COLOR;
    }

    return process.env.VUE_APP_PARKING_APPLICATION_COLOR;
  }

  export function getMonth2Digits(monthValue) {
    // 👇️ Add 1, because getMonth is 0-11
    const month = monthValue + 1;
  
    if (month < 10) {
      return '0' + month;
    }
  
    return month;
  }

  export function getDay2Digits(dayValue) {
  const day = dayValue;

  if (day < 10) {
    return '0' + day;
  }

  return day;
}
  
  export default {
    randomElement,
    toggleFullScreen,
    kebab,
    applicationMediaTypeColor
  };