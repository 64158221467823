<template>
    <v-app-bar class="elevation-1" color="secondary" dark fixed app>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-show="!mobileSearch"></v-app-bar-nav-icon>
        <v-progress-circular
        indeterminate
        color="white"
        size="24"
        v-show="showProgress"
        ></v-progress-circular>
            <!--<v-toolbar-title style="margin-left:10px;" class="" v-show="!mobileSearch && !drawer">{{ app.title }}</v-toolbar-title>-->
            <v-toolbar-title style="margin-left:10px;" class="" v-if="!mobileSearch && !drawer && (!searchFieldState)">{{ appTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- mobile search -->
        <!--<v-text-field
            placeholder="Išči..."
            color="white"
            append-icon="close"
            @click:append="hideMobileSearch()"
            prepend-inner-icon="search"
            v-model="search"
            v-show="mobileSearch"
            v-on:keyup.esc="hideMobileSearch()"
            style="width:100%;"
            class="hidden-md-and-up"
            ref="search"
            maxlength="30"
        ></v-text-field>-->
        <v-spacer></v-spacer>
        <!--<v-btn icon class="hidden-md-and-up" @click="btnSearch()" v-show="!mobileSearch">
            <v-icon>search</v-icon>
        </v-btn>-->
        <!--<v-menu offset-y class="hidden-sm-and-down" light>-->
        <app-toolbar-search></app-toolbar-search> <!-- search -->
        <v-toolbar-items>
            <v-btn @click="goToHomePage()" text v-if="!mobile">
                <v-icon left>mdi-home</v-icon>
                <span class="btn_toolbar_link">Domov</span>
            </v-btn>    
            <v-divider vertical  v-if="!$vuetify.breakpoint.xsOnly"></v-divider>
            <v-menu offset-y class="" light v-show="!mobileSearch" bottom left>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" style="margin-right:1px;">
                        <v-icon color="">mdi-tune</v-icon>
                    </v-btn>
                </template>
                <!--<v-list class="hidden-sm-and-down" color="grey">-->
                <v-list class="" v-show="!mobileSearch">
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>{{ userIcon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title><span class="">{{ user.name }}</span></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="goToHomePage()">
                        <v-list-item-icon>
                            <v-icon>mdi-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title><span class="">Domov</span></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <!--<v-list-item  @click="handleFullScreen()" v-show="!mobile">
                        <v-list-item-action>
                        <v-icon>mdi-fullscreen</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title><span>Fullscreen</span></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>-->
                    <v-divider></v-divider>
                    <v-list-item @click="logout">
                        <v-list-item-action>
                            <v-icon color="grey darken-3">mdi-logout</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title><span class="">Odjava</span></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-toolbar-items>
    </v-app-bar>    
</template>

<script>
import router from '@/router'
import Util from '@/helpers/utilities.js'
import { Role } from '@/helpers/role'
import { getUserRole } from '@/helpers/utilities'
import { EventBus } from '@/main.js'
const AppToolbarSearch = () => import('@/components/layout/AppToolbarSearch.vue')

export default {
    name: 'app-toolbar',
    components: {
        AppToolbarSearch
    },
    data: () => ({
        //mobileSearch: false
    }),
    
    watch: {

    },

    computed: {
        listMarginLeft() {
            if(this.$vuetify.breakpoint.lgAndUp) {
                return true;
            }

            return false;
        },
        user() {
            return this.$store.getters.user
        }, 

        drawer: {
            get() {
                return this.$store.getters.drawer
            },

            set(newValue) {
                this.$store.commit('SET_DRAWER', newValue)
            }
        },

        toolbarTitle() {
            return this.$store.getters.toolbarTitle
        },

        showProgress() {
            return this.$store.getters.progress
        },

        searchFieldState() {
            return this.$store.getters.searchFieldState
        },

        search: {
            get() {
                return this.$store.getters.search
            },

            set(newSearch) {
                this.$store.commit('SET_SEARCH', newSearch)
            }
        },

        app() {
            return this.$store.getters.app
        },

        mobile() {
            if(this.$vuetify.breakpoint.smAndDown) {
                return true;
            }

            return false;
        },

        userIcon() {

            if(getUserRole() === Role.Admin) {
                return 'mdi-incognito'
            }
            
            return 'mdi-account-circle'
        },

        mobileSearch: {
            get() {
                return this.$store.getters.mobileSearch
            },

            set(value) {
                this.$store.commit('SET_MOBILE_SEARCH', value)                
            }

        },

        appTitle() {
            if(this.$vuetify.breakpoint.smAndDown) {
                return this.$store.getters.app.shortTitle
            }

            return this.$store.getters.app.title
            
        }
    },

    methods: {
        logout() {
            this.$store.commit('SET_LOADER_TEXT', 'Odjava uporabnika je v teku...')
            this.$store.commit('SET_LOADER', true)

            this.$store.dispatch('LOGOUT')
            .then(response => {
                EventBus.$emit('ROOT_BUS', {
                    clearResourcesInterval: true,
                    clearAdminResourcesInterval: true,
                });
                
                setTimeout(() => { 
                    this.$store.commit('SET_LOADER', false) 
                    router.push({
                        name: 'login'
                    })  

                    this.$store.commit('SET_GDPR_ACCESS_LOG', true)
                }, 800)
            })
            .catch(error =>{
                setTimeout(() => { this.$store.commit('SET_LOADER', false) }, 800),
                window.console.log('Logout error')
                window.console.log(error)

            })
        },

        hideMobileSearch() {
            this.$store.commit('SET_SEARCH', '');
            this.mobileSearch = false;
        },

        btnSearch() {
            this.mobileSearch = !this.mobileSearch
            setTimeout(() =>{
                this.$refs.search.focus();
            }, 500)
        },

        handleFullScreen () {
            Util.toggleFullScreen();
        },

        goToHomePage() {
            this.$router.push({
                name: 'home_page'
            })
        }
    },

    created() {

    },

    mounted() {

    },

    destroyed() {
        
    }
}
</script>

<style>

</style>




