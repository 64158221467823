var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":!_vm.wideLayout,"justify-center":!_vm.wideLayout,"fill-height":!_vm.wideLayout}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":_vm.wideLayout,"lg12":_vm.wideLayout,"md10":!_vm.wideLayout,"lg10":!_vm.wideLayout,"xl8":!_vm.wideLayout}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":5,"options":_vm.options,"loading":_vm.loading,"search":_vm.search,"footer-props":{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                itemsPerPageOptions: [5,10,15,25,50, 100]
                }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"elevation-1",attrs:{"color":"white"}},[_c('v-toolbar-title',{staticClass:"font-weight-regular body-1"},[_vm._v("Pripadajoče vloge")]),_c('v-spacer'),_c('v-flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.breakPoint),expression:"breakPoint"}],attrs:{"xs4":"","md4":"","lg4":""}},[_c('v-text-field',{staticClass:"pt-4",attrs:{"label":"Išči...","append-icon":"mdi-magnify","single-line":"","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.search = ''},"click:clear":function($event){return _vm.doSearch()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.btnRefresh)?_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}):_vm._e(),(_vm.btnRefresh)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolbar_btn_fab",attrs:{"elevation":"0","color":"secondary","fab":"","x-small":"","dark":""},on:{"click":_vm.refreshDataTable}},on),[_c('v-icon',[_vm._v("mdi-sync")])],1)]}}],null,false,948541482)},[_c('span',[_vm._v("Osveži podatke")])]):_vm._e()],1),_c('br')]},proxy:true},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":_vm.editItemUrl(item)}},[_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"id":"dt_btn_edit","color":"success","small":""}},on),[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Uredi")])]),_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"id":"dt_btn_delete","color":"error","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Izbriši")])])]}},{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"absolute":"","indeterminate":"","height":_vm.progressHeight()}})]},proxy:true},{key:"item.application_status",fn:function(ref){
                var item = ref.item;
return [(item.application_status !== null && item.application_status !=='null' && item.application_status !== '')?_c('v-chip',{attrs:{"label":"","color":_vm.getApplicationStatusColor(item.application_status),"dark":"","small":""}},[_vm._v(_vm._s(item.application_status.name))]):_c('span',[_vm._v(_vm._s(item.application_status.name))])]}},{key:"item.payment_status",fn:function(ref){
                var item = ref.item;
return [(item.application_status !== null && item.payment_status !=='null' && item.payment_status !== '')?_c('v-chip',{attrs:{"label":"","color":_vm.getPaymentStatusColor(item.payment_status),"dark":"","small":""}},[_vm._v(_vm._s(item.payment_status.name))]):_c('span',[_vm._v(_vm._s(item.payment_status.name))])]}},{key:"item.digital",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":_vm.getMediaTypeColor(item.digital),"dark":"","small":""}},[_vm._v(_vm._s(_vm.getMediaTypeName(item.digital)))])]}}])},[_c('template',{slot:"no-data"},[_c('div',{staticClass:"no_data"},[_vm._v(_vm._s((this.loading) ? "Obdelava podatkov je v teku ..." : "Ni podatkov za prikaz."))])]),_c('template',{slot:"no-results"},[_c('div',{staticClass:"no_data"},[_vm._v("Ni podatkov za prikaz.")])]),_c('template',{slot:"loading"},[_c('div',{staticClass:"no_data"},[_vm._v("Obdelava podatkov je v teku ...")])])],2)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"540"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title'),_c('v-card-text',[_c('span',[_c('v-icon',{attrs:{"text":""}},[_vm._v("mdi-delete")]),_vm._v(" "+_vm._s(_vm.deleteItemText())+" ")],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},nativeOn:{"click":function($event){return _vm.dialogCancel()}}},[_vm._v("Prekliči")]),_c('v-btn',{attrs:{"text":"","loading":_vm.loading,"color":"success"},nativeOn:{"click":function($event){return _vm.dialogConfirm()}}},[_vm._v("Nadaljuj")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }