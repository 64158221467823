var CryptoJS = require('crypto-js')

export default {
    SET_USER(state, data) {
        if(data == null)
        {
            state.user.id = null
            state.user.name = null
            state.user.email = null
            state.user.type = null
            state.user.reset = null
            state.user.last_seen = null
            state.user.access_token = ""
        }
        else
        {
            state.user.id = data.id
            state.user.name = data.name
            state.user.email = data.email
            state.user.type = data.type
            state.user.reset = data.reset
            state.user.last_seen = data.last_seen
            state.user.access_token = data.access_token
        }

        //localStorage.setItem('ql42avtjam', JSON.stringify(state.user))

        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(state.user), process.env.VUE_APP_CRYPTO_JS_KEY).toString();

        // Decrypt
        var bytes  = CryptoJS.AES.decrypt(ciphertext, process.env.VUE_APP_CRYPTO_JS_KEY);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        //localStorage.setItem(process.env.VUE_APP_KEY, JSON.stringify(state.user))
        localStorage.setItem(process.env.VUE_APP_KEY, JSON.stringify(ciphertext))
    },

    SET_GDPR_ACCESS_LOG(state, data) {
        if(data == null || data == undefined) {
            state.gdpr_flag = null
        }
        else {
            state.gdpr_flag = data
        }

        localStorage.setItem('gdpr_flag', (data == true) ? '1' : '0')
    },

    SET_USER_EMAIL(state, data) {
        if(data == null || data == undefined) {
            state.user.email = null
        }
        else {
            state.user.email = data
        }

        localStorage.setItem(process.env.VUE_APP_KEY, JSON.stringify(state.user))
    },

    SET_USER_PW_RESET(state, data) {
        if(data == null || data == undefined) {
            state.user.reset = null
        }
        else {
            state.user.reset = data
        }

        localStorage.setItem(process.env.VUE_APP_KEY, JSON.stringify(state.user))
    },

    SET_DRAWER (state, value) {
         state.drawer = value
    },

    SET_TOOLBAR_TITLE (state, title) {
        state.toolbarTitle = title
    },

    SET_LOADER (state, value) {
        state.loader = value
    },

    SET_PROGRESS (state, value) {
        state.progress = value
    },

    SET_LOADER_TEXT (state, text) {
        state.loaderText = text
    },

    SET_SEARCH (state, search) {
        state.search = search
    },

    SET_SEARCH_FIELD_STATE (state, fieldState) {
        state.searchFieldState = fieldState
    },

    SET_MOBILE_SEARCH (state, mobileSearchState) {
        state.mobileSearch = mobileSearchState
    },

    SET_SNACKBAR_TEXT (state, text) {
        state.snackbar.text = text
    },

    SET_SNACKBAR_DISPLAY (state, display) {
        state.snackbar.display = display
    },
    
    SET_SNACKBAR_Y (state, y) {
        state.snackbar.y = y
    },

    SET_SNACKBAR_X (state, x) {
        state.snackbar.x = x
    },

    SET_SNACKBAR_MODE (state, mode) {
        state.snackbar.mode = mode
    },

    SET_SNACKBAR_TIMEOUT (state, timeout) {
        state.snackbar.timeout = timeout
    },

    SET_ACCESS_LOG (state, data) {
        state.access_log = data
    },

    SET_CURRENT_ROUTE (state, routeName) {
        state.currentRoute = routeName
    },

    SET_USER_TYPES(state, data) {
        state.userTypes = data
    },

    SET_PERMIT_TYPES(state, data) {
        state.permitTypes = data
    },

    SET_RESIDENCE_TYPES(state, data) {
        state.residenceTypes = data
    },

    SET_ENTITIES(state, data) {
        state.entities = data
    },

    SET_VEHICLE_OWNERSHIP_TYPES(state, data) {
        state.vehicleOwnershipTypes = data
    },

    SET_APPLICATION_STATUSES(state, data) {
        state.applicationStatuses = data
    },

    SET_PERMIT_STATUSES(state, data) {
        state.permitStatuses = data
    },

    SET_PAYMENT_STATUSES (state, data) {
        state.paymentStatuses = data
    },

    SET_PARKING_LOTS(state, data) {
        state.parkingLots = data
    },

    SET_PILLARS(state, data) {
        state.pillars = data
    },

    SET_INFO_POGOJI_UPORABE(state, data){
        state.infoPogojiUporabe = data
    },

    SET_INFO_GDPR(state, data) {
        state.infoGdpr = data
    },

    SET_INFO_INSTRUCTIONS(state, data) {
        state.infoInstructions = data
    }
}