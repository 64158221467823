import axios from 'axios'
import Store from '@/store'

//export const API_ENDPOINT = 'http://dovolilnice-bled.test/api/'

export const API_ENDPOINT = 'https://demo.conforma.si/dovolilnice-bled/public/api/'
//export const DEMO_API_ENDPOINT = "http://demo-api.test/api/"

export function get(url) {
    return axios({
        method: 'GET',
        url: url,
        headers: {
            'Authorization': `Bearer ${Store.state.user.access_token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
    	}
    })
}

export function post(url, payload) {
    return axios({
    	method: 'POST',
    	url: url,
    	data: payload,
    	headers: {
            'Authorization': `Bearer ${Store.state.user.access_token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
    	}
    })
}

export function put(url, payload) {
    return axios({
        method: 'POST',
        url: url,
        data: payload,
        headers: {
            'Authorization': `Bearer ${Store.state.user.access_token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
    	}
    })
}

export function destroy(url) {
    return axios({
        method: 'POST',
        url: url,
        headers: {
            'Authorization': `Bearer ${Store.state.user.access_token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
    	}
    })
}

export function download(url) {
    return axios({
        method: 'GET',
        url: url,
        responseType: 'blob', // important
        headers: {
            'Authorization': `Bearer ${Store.state.user.access_token}`
    	}
    })
}

export function interceptors(cb) {
    axios.interceptors.response.use((res) => {
        return res;
    }, (err) => {
        cb(err)
        return Promise.reject(err)
    })
}