<template>
    <v-container grid-list-xl :fluid="fluid" style="">
        <v-layout row wrap :align-center="boxedLayout" :justify-center="boxedLayout" :fill-height="boxedLayout">
            <v-flex xs12 sm12 :md12="!boxedLayout" :lg12="!boxedLayout" :md10="boxedLayout" :lg10="boxedLayout" :xl8="boxedLayout">
                <v-toolbar class="elevation-1" dense>
                    <v-toolbar-title><span  class="font-weight-regular body-1">{{ title }}</span></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <div v-show="displayBreadCrumbs">
                        <v-breadcrumbs :items="breadcrumbsArray" divider="-" dense>
                            <template  v-slot:item="props">
                                <v-breadcrumbs-item v-if="props.item.text == 'dashboard'">
                                    <v-icon small>mdi-home</v-icon>
                                </v-breadcrumbs-item>
                                <v-breadcrumbs-item v-else :to="props.item.href">
                                    {{ props.item.text }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </div>
                    <div v-show="!displayBreadCrumbs">
                        <v-icon>{{ icon }}</v-icon>
                    </div>
                </v-toolbar>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { AdminMenu, UserMenu } from '@/helpers/menu';
import { getUserRole } from '@/helpers/utilities'
import { Role } from '@/helpers/role'

export default {
    name: 'app-page-header',
    data: () => ({
        title: '',
        icon: '',
        routeName: null,
        fluid: false,
        breadcrumbsArray: []   
    }),

    watch: {

    },

    computed: {
        displayBreadCrumbs() {
            if(this.$vuetify.breakpoint.mdAndUp) {
                return true
            }

            return false
        },

        boxedLayout() {
            //window.console.log(this.$store.getters.currentRouteName)
            
            let routeName = this.$store.getters.currentRouteName
            let vm = this;
            if(routeName == 'admin_settings' || routeName == 'settings') {
                return true
            }

            if(routeName == 'admin_create_parking_permit') {
                return true
            }

            if(routeName == 'admin_create_promenada_permit') {
                return true
            }

            if(routeName == 'admin_users_edit') {
                vm.title = 'Uredi uporabnika'
                vm.icon = 'mdi-account-circle'
                vm.fluid = true
                return false
            }

            if(routeName == 'admin_payments' || routeName == 'admin_dashboard' || routeName == 'dashboard') {
                vm.fluid = true
                return false
            }

            if(routeName == 'admin_applications_edit') {
                vm.title = 'Uredi e-vlogo'
                vm.icon = 'mdi-file-document-edit-outline'
                return true
            }

            if(routeName == 'admin_applications_edit2') {
                vm.title = 'Uredi vlogo'
                vm.icon = 'mdi-file-document-edit-outline'
                return true
            }

            if(routeName == 'admin_create_parking_permit') {
                return true;
            }

            if(routeName == 'admin_permits' || routeName == 'admin_applications' || routeName == 'admin_users') {
                vm.fluid = true
            }

            if(routeName == 'admin_permits_edit') {
                vm.title = 'Uredi dovolilnico'
                vm.icon = 'mdi-file-document-box-check-outline'
                return true
            }

            if(routeName == 'user_parking_application') {
                vm.title = 'Vloge'
                vm.icon = 'mdi-file-document-box-edit-outline'
                return true
            }

            if(routeName == 'user_promenada_application') {
                vm.title = 'Vloge'
                vm.icon = 'mdi-file-document-box-edit-outline'
                return true
            }

            if(routeName == 'user_permits_replacement') {
                vm.title = "Zamenjava dovolilnice"
                vm.icon = "mdi-shuffle-variant"
                return true
            }

            if(routeName == 'admin_notifications_edit') {
                vm.title = 'Obveščanje'
                vm.icon = 'mdi-android-messages'
                return true
            }

            if(routeName == 'admin_notifications') {
                return true
            }

            if(routeName == 'admin_user_promenada_permit' || routeName == 'admin_user_parking_permit') {
                return true
            }

            if(routeName == 'admin_applications_parking_permit') {
                vm.title = 'Vloga za izdajo parkirne dovolilnice'
                return true;
            }

            return false
        }
    },

    methods: {
        adminBreadcrumbs: function () {
            let breadcrumbs = [];
            let homeCrumb = {
                text: 'dashboard',
                disabled: false
            };

            breadcrumbs.push(homeCrumb);
            AdminMenu.forEach(item => {
                if (item.items) {
                let child =  item.items.find(i => {
                    return i.name === this.$route.name;
                })
                if (child) {
                    breadcrumbs.push({ text: item.title, disabled: false, href: item.path });
                    breadcrumbs.push({ text: child.title, disabled: false, href: child.path });
                    this.title = child.title;
                    this.icon = child.icon
                } 

                if(item.hasOwnProperty('items')) {
                    item.items.forEach(item_item => {
                        if(item_item.hasOwnProperty('items')) {
                            item_item.items.forEach(item_item_item => {
                                if(item_item_item.name === this.$route.name) {
                                    breadcrumbs.push({ text: item.title, disabled: false, href: item.path });
                                    breadcrumbs.push({ text: item_item.title, disabled: false, href: item_item.path });
                                    breadcrumbs.push({ text: item_item_item.title, disabled: false, href: item_item_item.path });
                                    this.title = item_item_item.title;
                                    this.icon = item_item_item.icon
                                    return
                                }
                            })
                        }
                    })
                }

                } else {
                if (item.name === this.$route.name) {
                    this.title = item.title;
                    this.icon = item.icon
                    breadcrumbs.push({ text: item.title, disabled: false, href: item.path});
                }
                }
            });

            this.breadcrumbsArray = breadcrumbs
        },

        userBreadcrumbs: function () {
            let breadcrumbs = [];
            let homeCrumb = {
                text: 'dashboard',
                disabled: false
            };

            breadcrumbs.push(homeCrumb);
            UserMenu.forEach(item => {
                if (item.items) {
                let child =  item.items.find(i => {
                    return i.name === this.$route.name;
                })
                if (child) {
                    breadcrumbs.push({ text: item.title, disabled: false, href: item.path });
                    breadcrumbs.push({ text: child.title, disabled: false, href: child.path });
                    this.title = child.title;
                    this.icon = child.icon
                } 

                if(item.hasOwnProperty('items')) {
                    item.items.forEach(item_item => {
                        if(item_item.hasOwnProperty('items')) {
                            item_item.items.forEach(item_item_item => {
                                if(item_item_item.name === this.$route.name) {
                                    breadcrumbs.push({ text: item.title, disabled: false, href: item.path });
                                    breadcrumbs.push({ text: item_item.title, disabled: false, href: item_item.path });
                                    breadcrumbs.push({ text: item_item_item.title, disabled: false, href: item_item_item.path });
                                    this.title = item_item_item.title;
                                    this.icon = item_item_item.icon
                                    return
                                }
                            })
                        }
                    })
                }

                } else {
                if (item.name === this.$route.name) {
                    this.title = item.title;
                    this.icon = item.icon
                    breadcrumbs.push({ text: item.title, disabled: false, href: item.path});
                }
                }
            });

            this.breadcrumbsArray = breadcrumbs
        },

        goToHomePage() {
            this.$router.push({
                name: 'home_page'
            })
        }
    },

    created() {
        this.routeName = this.$route.name
        //this.breadcrumbs()
        if(getUserRole() === Role.Admin) {
            this.adminBreadcrumbs()
            //window.console.log(1)
        } else {
            this.userBreadcrumbs()
            //window.console.log(2)
        }

        if(this.routeName === 'admin_create_parking_permit' || this.routeName === 'admin_user_parking_permit') {
            this.icon = 'mdi-parking'
            this.title = "Parkirna dovolilnica"
        }

        if(this.routeName === 'admin_create_promenada_permit' || this.routeName === 'admin_user_promenada_permit') {
            this.icon = 'mdi-city-variant-outline'
            this.title = "Dovolilnica za dostop na Blejsko promenado"
        }
    },

    mounted() {
        //window.console.log("Role: ")
        //window.console.log(getUserRole())
    },

    destroyed() {

    }
}
</script>

<style>
    .theme--light.v-breadcrumbs .v-breadcrumbs__item--disabled {
        color: rgba(0, 0, 0, 0.7) !important;
    }
</style>