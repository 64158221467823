<template>
    <div>
        <v-dialog v-model="gdprDialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="dialog_card_title">
            <span class="subtitle-1 font-weight-medium">Izberite eno izmed spodnjih možnosti</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>         
            <v-container fluid>
                <!-- form -->
                <div class="div__divider__15"></div>
                <v-alert
                border="right"
                colored-border
                color="secondary"
                type="info"
                elevation="1"
              >
                {{ infoText }}
              </v-alert>
              <v-form ref="form" valid="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-checkbox
                          v-model="selected"
                          label="Vpogled v dovolilnico"
                          hide-details
                          value="1"
                      ></v-checkbox>
                      <v-checkbox
                          v-model="selected"
                          label="Vpogled v vlogo"
                          hide-details
                          value="2"
                      ></v-checkbox>
                      <v-checkbox
                          v-model="selected"
                          label="Dodajanje nove dovolilnice"
                          hide-details
                          value="3"
                      ></v-checkbox>
                      <v-checkbox
                          v-model="selected"
                          label="Urejanje dovolilnice"
                          hide-details
                          value="4"
                      ></v-checkbox>
                      <v-checkbox
                          v-model="selected"
                          label="Urejanje vloge"
                          hide-details
                          value="5"
                      ></v-checkbox>
                      <v-checkbox
                          v-model="cb6"
                          label="Drugo: Vpišite drug razlog"
                          value="999"
                          :rules="(ruleCb6Required) ? cb6Rule : []"
                      ></v-checkbox>

                      <v-divider v-if="enableTextarea"></v-divider>
                      <div v-if="enableTextarea" class="div__divider__20"></div>

                      <v-textarea
                      v-if="enableTextarea"
                      outlined
                      name="input-7-4"
                      label="Vnesite željeno besedilo..."
                      v-model="note"
                      :rules="(cb6 == '999') ? noteRule : []"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
            </v-container>
          </v-card-text>
        <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="save()" @click.native="save()" @ color="error" :loading="loading" text>Shrani</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>

export default {
    name: 'gdpr-access-log',
    data: () => ({
        valid: true,
        loading: false,
        selected: [],
        cb6: null,
        note: null,
        infoText: 'Za potrebe GDPR je potrebno izbrati s kakšnim namenom vstopate v spletno aplikacijo “dovolilnice.e-bled.si”.',

        cb6Rule: [
            v => (!!v) || 'Izbira vsaj ene izmed možnosti je obvezna',
        ],

        noteRule: [
          v => (!!v) || 'Vnos besedila je obvezen',
        ]

        
    }),

    watch: {

    },

    computed: {
        gdprDialog: {
            get() {
                //return (this.$store.getters.gdprAccessLog == null || this.$store.getters.gdprAccessLog == false) ? false : true
                return this.$store.getters.gdprAccessLog
            },

            set(value) {
                this.$store.commit('SET_GDPR_ACCESS_LOG', value)
            }
        },

        enableTextarea() {
          if(this.cb6 == '999') {
            return true
          }

          return false
        }, 

        ruleCb6Required() {
          if(this.selected.length < 1) {
            return true
          }

          return false
        }
    },

    methods: {
        save() {
          var vm = this
          if(this.$refs.form.validate()) {
            vm.loading = true
            vm.$store.dispatch('CREATE_GDPR_ACCESS_LOG', vm.createDataObject())
            .then(response => {
              //window.console.log(response)
            })
            .catch(error => {
              window.console.error("### GdprAccessLog@save ###");
              window.console.error(error)
            })
            .finally(() => {
              setTimeout(() => {
                vm.loading = false;
                vm.$store.commit('SET_GDPR_ACCESS_LOG', false) 
              }, 600)
            })
          } 
        },

        createDataObject() {
          var vm = this
          return {
            selected: vm.selected,
            cb6: vm.cb6,
            note: vm.note
          }
        }
    },

    created() {

    },

    mounted() {

    },

    destroyed() {

    }
}

</script>

<style scoped>

</style>