<template>
    <div>
        <div class="text-center" v-if="!itemsEmpty">
            <v-chip v-for="(item, key) in items" :key="key"
            class="ma-1"
            :color="getColor"
            >
                <v-icon left>{{ getIcon }}</v-icon>
                {{ item.name }}
            </v-chip>
        </div>
    </div>
</template>

<script>

export default {
    props: ['items', 'color', 'icon'],
    name: 'chips',

    data: () => ({

    }),

    watch: {

    },

    computed: {
        itemsEmpty() {
            if(this.items.length) {
                return false
            }

            return true
        },

        getColor() {
            if(this.color != undefined && this.color != '') {
                return this.color
            }

            return ''
        },

        getIcon() {
            if(this.icon != undefined && this.icon != '') {
                return this.icon
            }

            return ''
        }
    },

    methods: {

    },

    created() {

    },

    mounted() {
        //window.console.log("Items: " + this.items)
    },

    destroyed() {

    }
}

</script>