<template>
  <v-app>
    <transition name="fade">
      <div>
        <router-view></router-view>
      </div>
    </transition>

    <info-pogoji-uporabe></info-pogoji-uporabe>
    <info-gdpr></info-gdpr>
    <info-navodila-2021></info-navodila-2021>

    <cookie-law theme="dark-lime" buttonText="Razumem">
      <div slot="message">
        To spletno mesto uporablja piškotke za boljšo uporabniško izkušnjo. Če boste spletno stran uporabljali še naprej, s tem privolite v njihovo nadaljnjo uporabo. 
        <!--<router-link to="legal-notes">Click here</router-link>-->
      </div>
    </cookie-law>
  </v-app>
</template>

<script>
  import CookieLaw from 'vue-cookie-law'

  import { interceptors } from '@/helpers/api'
  const InfoPogojiUporabe = () => import('@/components/InfoPogojiUporabe.vue')
  const InfoGdpr = () => import('@/components/InfoGdpr.vue')
  //const InfoNavodila = () => import('@/components/InfoNavodila.vue')
  const InfoNavodila2021 = () => import('@/components/InfoNavodila2021.vue')
  export default {
    
    components: { 
      CookieLaw,
      InfoPogojiUporabe,
      InfoGdpr,
      //InfoNavodila,
      InfoNavodila2021
    },

    data: () => ({

    }),

    watch: {

    },

    computed: {

    },

    methods: {

    },

    created() {
      interceptors((err) => {
        if(err.response.status === 401) {
          //Auth.remove()
          //revoke() clear local storage
          this.$store.commit('SET_LOADER', false)
          this.$store.commit('SET_USER', null);
          if(this.$route.name != 'login') {
            return this.$router.push({ name: 'login'})
          }
        }

        if(err.response.status === 500) {
          //Flash.setError(err.response.statusText)
          return this.$router.push({name: '500'})
        }

        if(err.response.status === 404) {
        //https://www.bountysource.com/issues/38142121-missing-param-for-named-route-notfound-expected-0-to-be-defined
          return this.$router.push({ name: '404', params: { '0': '404' } }) 
        }

        if(err.response.status === 403) {
          //return this.$router.push({ name: '403', params: { '0': '403' } }) 
          window.console.clear();
          if(this.$route.name != '403') {
            return this.$router.push({ name: '403'})
          }
      }

      })
    },

    mounted() {
      
    },

    destroyed() {
    }
  }
</script>


<style>
  html { overflow-y: auto }
  .pointer {cursor: pointer;}
  .fade-enter-active, .fade-leave-active {
        transition-property: opacity;
        transition-duration: .25s;
    }

    .fade-enter-active {
        transition-delay: .25s;
    }

    .fade-enter, .fade-leave-active {
        opacity: 0
    }

</style>