const AdminMenu = [
    { header: 'Nadzorna Plošča' },
    {
        name: 'admin_dashboard',
        title: 'Pregled',
        icon: 'mdi-view-dashboard',
        path: '/admin/22ehke0d/nadzorna-plosca',
        group: 'dashboard'
    },
    {
        name: 'admin_settings',
        title: 'Nastavitve',
        icon: 'mdi-cogs',
        path: '/admin/22ehke0d/nastavitve',
        group: 'settings'
    },
    /*{
        name: 'admin_notifications',
        title: 'Obveščanje',
        icon: 'mdi-android-messages',
        path: '/admin/obvescanje',
        group: 'notifications'
    },*/
    
    {
        title: 'Obveščanje',
        icon: 'mdi-android-messages',
        group: 'obvescanje',
        items: [
            {
                name: 'admin_notifications',
                title: 'Dodaj obvestilo',
                icon: 'mdi-android-messages',
                path: '/admin/22ehke0d/obvescanje',
            },
            {
                name: 'admin_notifications_list',
                title: 'Seznam obvestil',
                icon: 'mdi-android-messages',
                path: '/admin/22ehke0d/obvescanje/seznam',
            }
        ]
    },
    {
        name: 'admin_payments',
        title: 'Plačila',
        icon: 'mdi-currency-eur',
        path: '/admin/22ehke0d/placila',
        group: 'payments'
    },
    {
        name: 'admin_users',
        title: 'Uporabniki',
        icon: 'mdi-account-group',
        path: '/admin/22ehke0d/uporabniki',
        group: 'users'
    },
    {
        name: 'admin_applications',
        title: 'Vloge',
        icon: 'mdi-file-document-edit-outline',
        path: '/admin/22ehke0d/vloge',
        group: 'applications'
    },
    {
        name: 'admin_permits',
        title: 'Dovolilnice',
        icon: 'mdi-file-document-box-check-outline',
        path: '/admin/22ehke0d/dovolilnice',
        group: 'permits'
    },

    {
        name: 'admin_exports',
        title: 'Izvoz podatkov',
        icon: 'mdi-application-export',
        path: '/admin/22ehke0d/izvoz-podatkov',
        group: 'exports',
    },
    

];

const UserMenu = [
    { header: 'Nadzorna Plošča' },
    {
        name: 'dashboard',
        title: 'Pregled',
        icon: 'mdi-view-dashboard',
        path: '/nadzorna-plosca',
        group: 'dashboard'
    },
    {
        name: 'settings',
        title: 'Nastavitve',
        icon: 'mdi-cogs',
        path: '/nastavitve',
        group: 'settings'
    }
];

export {
    AdminMenu,
    UserMenu
}