export default {
    user (state) {
        return state.user
    },

    gdprAccessLog (state) {
        return state.gdpr_flag
    },  

    loader (state) {
        return state.loader
    },

    progress (state) {
        return state.progress
    },

    loaderText (state) {
        return state.loaderText
    },

    drawer (state) {
        return state.drawer
    },

    toolbarTitle (state) {
        return state.toolbarTitle
    },

    snackbar (state) {
        return state.snackbar
    },

    search (state) {
        return state.search
    },

    searchFieldState (state) {
        return state.searchFieldState
    },

    mobileSearch (state) {
        return state.mobileSearch
    },

    app (state) {
        return state.app
    },

    access_log (state) {
        return state.access_log
    },

    currentRouteName (state) {
        return state.currentRoute
    },

    userTypes(state) {
        return state.userTypes
    },

    permitTypes(state) {
        return state.permitTypes
    },

    residenceTypes(state) {
        return state.residenceTypes
    },

    entities(state) {
        return state.entities
    },

    vehicleOwnershipTypes(state) {
        return state.vehicleOwnershipTypes
    },

    permitStatuses(state) {
        return state.permitStatuses
    },

    applicationStatuses(state) {
        return state.applicationStatuses
    },

    paymentStatuses(state) {
        return state.paymentStatuses
    },

    parkingLots(state) {
        return state.parkingLots
    },

    pillars(state) {
        return state.pillars
    },

    infoPogojiUporabe(state) {
        return state.infoPogojiUporabe
    },

    infoGdpr(state) {
        return state.infoGdpr
    },

    infoInstructions(state) {
        return state.infoInstructions
    }
}