<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="600px">
                <v-card>
                    <v-card-title class="dialog_card_title"><v-icon left v-if="$vuetify.breakpoint.smAndUp">mdi-cogs</v-icon>{{ formTitle }}</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style="auto">
                        <br>
                        <form-validation-messages :messages="apiValidationMessages"></form-validation-messages>
                        <form-submit-messages :messages="apiMessages" :errorMessages="apiErrorMessages"></form-submit-messages>

                        <v-alert
                        border="right"
                        colored-border
                        color="secondary"
                        type="info"
                        elevation="1"
                    >
                        {{ infoText }}
                    </v-alert>
                    <br>

                        <v-form id="set_user_account_data_form" ref="set_user_account_data_form" v-model="valid" lazy-validation>
                            <v-text-field
                                v-model="email"
                                label="E-mail"
                                required
                                :rules="email_rules"
                                append-icon="mdi-email"
                                @keyup.enter="updateEmail()"
                            ></v-text-field>

                                <v-text-field
                                v-model="password"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.min]"
                                :type="show1 ? 'text' : 'password'"
                                name="input-10-1"
                                label="Novo geslo"
                                hint=""
                                counter
                                @click:append="show1 = !show1"
                            ></v-text-field>

                            <v-text-field
                                v-model="password_confirmation"
                                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.min, passwordMatch]"
                                :type="show2 ? 'text' : 'password'"
                                name="input-10-1"
                                label="Ponovno vpišite novo geslo"
                                hint="Geslo naj vsebuje vsaj 6 unakov"
                                counter
                                @click:append="show2 = !show2"
                                @keyup.enter="updatePassword()"
                            ></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text  v-if="$vuetify.breakpoint.xsOnly">
                        <v-spacer></v-spacer>
                        <v-btn v-if="!submitted" block outlined style="margin-bottom:10px;margin-top:15px;border-color:#4caf50;" :disabled="loading ||submitted"  :loading="loading" color="success" text @click="submitForm()"><v-icon left>mdi-arrow-right</v-icon>Shrani</v-btn>
                        <v-btn v-if="!submitted" block outlined style="border-color:#b71c1c;" :disabled="loading" :loading="logout"  color="error" text @click="clearForm()"><v-icon left>mdi-close</v-icon>Prekliči</v-btn>
                        <v-btn v-if="submitted" block outlined style="border-color:#4caf50;"  color="success" text @click="closeDialog()"><v-icon left>mdi-arrow-right</v-icon>Nadaljuj</v-btn>
                    </v-card-text>
                    <v-card-actions v-else>
                        <v-btn v-if="!submitted"  :loading="loading" color="success" :disabled="loading ||submitted" text @click="submitForm()"><v-icon left>mdi-arrow-right</v-icon>Shrani</v-btn>
                        <v-btn v-if="!submitted" :loading="logout"  color="error" :disabled="loading" text @click="clearForm()"><v-icon left>mdi-close</v-icon>Prekliči</v-btn>
                        <v-btn v-if="submitted"  style="border-color:#4caf50;" color="success" text @click="closeDialog()"><v-icon left>mdi-arrow-right</v-icon>Nadaljuj</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
const FormValidationMessages = () => import('@/components/FormValidationMessages.vue')
const FormSubmitMessages = () => import('@/components/FormSubmitMessages.vue')
import { EventBus } from '@/main.js'


export default {
    name: 'set-user-account-data',
    components: {
        FormValidationMessages,
        FormSubmitMessages
    },

    data: () => ({
        user: null,
        submitted: false,
        valid: true,
        formTitle: 'Nastavitve uporabniškega računa',
        infoText: "Zaradi varnosti in lažje uporabe vašega uporabniškega računa v prihodnje je potrebno vpisati vaš e-naslov ter novo geslo za dostop do vašega uporabniškega računa.",
        dialog: true,
        loading: false,
        logout: false,
        disableSubmit: false,

        email: "",
        password: "",
        password_confirmation: "",

        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[],

        rules: {
          required: value => !!value || 'Vnos gesla je obvezen',
          min: v => v.length >= 8 || 'Geslo mora vsebovati vsaj 8 znakov (uporabite številke, znake ter velike in male črke)'
        },

        email_rules: [
            v => !!v || 'Polje e-mail je obvezno',
            v => /.+@.+\..+/.test(v) || 'Vnesen elektronski naslov ni pravilen'
        ],

        show1:false,
        show2:false,
    }),

    watch: {

    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        apiMessages() {
            return this.backendMessages;
        },

        apiErrorMessages() {
            return this.backendErrorMessages 
        },

        apiValidationMessages() {
            return this.backendValidationMessages
        }
    },

    methods: {
        submitForm() {
            var vm = this
            if(this.$refs.set_user_account_data_form.validate()) {
                vm.loading = true
                vm.backendValidationMessages = []
                vm.backendMessages = []
                vm.backendErrorMessages = []

                vm.$store.dispatch('UPDATE_USER_ACCOUNT_DATA', vm.createDataObject())
                .then(response => {
                    //window.console.log(response)
                    //vm.$store.commit('SET_USER_PW_RESET', 1) 
                    //window.console.log("vuex")
                    //window.console.log(this.$store.getters.user)
                    //window.console.log("localStorage")
                    //window.console.log(JSON.parse(window.localStorage.getItem('process.env.VUE_APP_KEY')))
                    vm.submitted = true;
                    //window.console.log(vm.backendMessages)
                    vm.backendMessages.push('Podatki so bili uspešno posodobljeni.');
                    //window.console.log(vm.backendMessages)
                })
                .catch(error => {
                    window.console.error("### SetUserAccountData@save ###");
                    window.console.error(error)

                    if(error.response.status === 422) {
                        //window.console.log(error.response.data.errors)
                        for (const property in error.response.data.errors) {
                            //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                            var propertyData = error.response.data.errors[property];
                            //window.console.log(propertyData);
                            propertyData.forEach(message => {
                                vm.backendValidationMessages.push(message);
                            })
                        }
                    } else {
                        vm.backendErrorMessages.push('Ups... pri posodobitvi podatkov je prišlo do napake.')
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                            vm.loading = false;
                        }, 600)
                    })
            } 
        },

        clearForm() {
            this.email = (this.user != null) ? this.user.email : ''
            this.password = ""
            this.password_confirmation = ""
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []
            this.$refs.set_user_account_data_form.resetValidation();

            this.logout = true

            this.$store.dispatch('LOGOUT')
            .then(response => {
                EventBus.$emit('ROOT_BUS', {
                    clearResourcesInterval: true,
                    clearAdminResourcesInterval: true,
                });
                setTimeout(() => { 
                    this.logout = false
                    this.$router.push({
                        name: 'login'
                    })
                }, 300)
            })
            .catch(error =>{
                window.console.log('Logout error')
                window.console.log(error)
                this.logout = false

            })

        },

        passwordMatch() {
            if(this.password !== this.password_confirmation) {
                return "Gesli se ne ujemata"
            }

            return true
        },

        createDataObject() {
            var vm = this
            return {
                email: vm.email,
                password: vm.password,
                password_confirmation: vm.password_confirmation
            }
        },

        closeDialog() {
            this.$store.commit('SET_USER_EMAIL', this.email)
            this.$store.commit('SET_USER_PW_RESET', 1)
            this.dialog = false
            //window.console.log("closeDialog")
            //window.console.log(JSON.parse(window.localStorage.getItem('process.env.VUE_APP_KEY')))
        }
    },

    created() {

    },

    mounted() {
        this.user = this.$store.getters.user;
        this.email = this.user.email
    },

    destroyed() {

    }
}

</script>