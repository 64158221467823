<template>
    <div>
      <v-toolbar
      color="secondary"
      dark
      style="opacity:1;"
  >
    <!--<v-toolbar-title>Dovolilnice | Občina Bled</v-toolbar-title>-->

    <!--<v-divider
      class="mx-4"
      vertical
    ></v-divider>-->
    <v-icon left @click="goToHomePage()">mdi-newspaper-variant-outline</v-icon>
    <span id="toolbar-title" @click="goToHomePage()" class="subtitle-1">Dovolilnice | Občina Bled</span>

    <v-spacer></v-spacer>

    <v-toolbar-items class="hidden-sm-and-down">
      <v-btn text @click="infoNavodila()">
        <v-icon left>mdi-information-variant</v-icon>
        <span class="btn_toolbar_link">Navodila za uporabo</span>
      </v-btn>

      <v-divider vertical></v-divider>

      <v-btn text @click="infoPogojiUporabe()">
        <v-icon left>mdi-lock</v-icon>
        <span style="text-transform:none;" class="btn_toolbar_link">Pogoji uporabe in zasebnost</span>
      </v-btn>

      <v-divider vertical></v-divider>

      <v-btn @click="goToHomePage()" text v-if="!isHomePage">
        <v-icon left>mdi-home</v-icon>
        <span class="btn_toolbar_link">Domov</span>
      </v-btn>

      <v-divider vertical v-if="isApplicationPage"></v-divider>

      <v-btn @click="goToLoginPage()" text v-if="!isLoginPage && !userLoggedIn">
        <v-icon left>mdi-login</v-icon>
        <span class="btn_toolbar_link">Prijava</span>
      </v-btn>
      <!--<v-divider vertical></v-divider>-->

    <v-menu offset-y class="" light v-if="userLoggedIn" bottom left>
          <template v-slot:activator="{ on }">
              <v-btn text v-on="on" style="margin-right:1px;">
                  <v-icon left>{{ userIcon }}</v-icon>
                  <span class="btn_toolbar_link">{{ userName }}</span>
              </v-btn>
          </template>
          <!--<v-list class="hidden-sm-and-down" color="grey">-->
          <v-list class="">
            <v-list-item @click="goToDashboard()">
                  <v-list-item-action>
                      <v-icon color="grey darken-3">mdi-view-dashboard</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                      <v-list-item-title><span class="">Nadzorna plošča</span></v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item @click="logout">
                  <v-list-item-action>
                      <v-icon color="grey darken-3">mdi-logout</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                      <v-list-item-title><span class="">Odjava</span></v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
          </v-list>
      </v-menu>
    </v-toolbar-items>

    <!-- mobile menu -->
    <v-menu offset-y light bottom left>
                <template v-slot:activator="{ on }">
                    <v-btn class="hidden-md-and-up" icon v-on="on" style="margin-right:1px;">
                        <v-icon>mdi-menu</v-icon>
                    </v-btn>
                </template>
                <v-list>
                  <v-list-item v-if="userLoggedIn" @click="goToSettings()">
                        <v-list-item-icon>
                            <v-icon>{{ userIcon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title><span class="">{{ userName }}</span></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="userLoggedIn" @click="goToDashboard()">
                        <v-list-item-icon>
                            <v-icon>mdi-view-dashboard</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title><span class="">Nadzorna plošča</span></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                  
                    <v-list-item @click="infoNavodila()">
                        <v-list-item-icon>
                            <v-icon>mdi-information-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title ><span class="">Navodila za uporabo</span></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="infoPogojiUporabe()">
                        <v-list-item-icon>
                            <v-icon>mdi-lock</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title ><span class="">Pogoji uporabe in zasebnost</span></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="goToHomePage()" text v-if="!isHomePage">
                        <v-list-item-icon>
                            <v-icon>mdi-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title ><span class="">Domov</span></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="!isLoginPage && !userLoggedIn" @click="goToLoginPage()">
                        <v-list-item-icon>
                            <v-icon>mdi-login</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title text><span class="">Prijava</span></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider v-if="userLoggedIn"></v-divider>
                    <v-list-item @click="logout" v-if="userLoggedIn">
                        <v-list-item-action>
                            <v-icon color="grey darken-3">mdi-logout</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title><span class="">Odjava</span></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
    <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
  </v-toolbar>
    </div>
</template>

<script>
import  { Role } from '@/helpers/role'
import { getUserSettingsFromStorage, getUserRole } from '@/helpers/utilities'

export default {
    name: 'app-bar-front-page',
    data: () => ({

    }),

    computed: {
        getLogo() {
            return 'https://cloud.conforma.si/public/img/obcina-bled/grb_bled_135_135.png'
        },

        isHomePage() {
          if(this.$route.name === 'home_page') {
            return true
          }

          return false
        },

        isLoginPage() {
          if(this.$route.name === 'login') {
            return true;
          }

          return false 
        },

        isApplicationPage() {
          if(this.$route.name === 'parking_application' || this.$route.name === 'promenade_application') {
            return true;
          }

          return false;
        },

        userLoggedIn() {
          let userVuexStore = this.$store.getters.user
          let userLocalStorage = getUserSettingsFromStorage(this.$store.getters.app.appKey)

          if(userVuexStore.access_token !== null && userLocalStorage.access_token !== null && userVuexStore.access_token === userLocalStorage.access_token) {
            if(userVuexStore.access_token !== '' && userLocalStorage.access_token !== '') {
              return true
            }
          }

          return false
        },

        userIcon() {
          if(getUserRole() === Role.Admin) {
              return 'mdi-incognito'
          }
          
          return 'mdi-account-circle'
        },

        userName() {
          return this.$store.getters.user.name
        }
    },

    methods: {
      goToHomePage() {
        if(this.$route.name !== 'home_page') {
          this.$router.push({
            name: 'home_page'
          })
        }
      },

      goToLoginPage() {
        if(this.$route.name !== 'login') {
          this.$router.push({
            name: 'login'
          })
        }
      },

      goToDashboard() {
          if(getUserRole() === Role.Admin) {
            this.$router.push({
              name: 'admin_dashboard'
            })
          }
          else {
            this.$router.push({
              name: 'dashboard'
            })
          }
      },

      goToSettings() {
        if(getUserRole() === Role.Admin) {
            this.$router.push({
              name: 'admin_settings'
            })
          }
          else {
            this.$router.push({
              name: 'settings'
            })
          }
      },

      infoPogojiUporabe() {
        this.$store.commit('SET_INFO_POGOJI_UPORABE',1);
      },

      infoNavodila() {
        this.$store.commit('SET_INFO_INSTRUCTIONS',1)
      },

      logout() {
            this.$store.commit('SET_LOADER_TEXT', 'Odjava uporabnika je v teku...')
            this.$store.commit('SET_LOADER', true)

            this.$store.dispatch('LOGOUT')
            .then(response => {

                setTimeout(() => { 
                    this.$store.commit('SET_LOADER', false) 
                    this.$router.push({
                        name: 'login'
                    })
                }, 800)
            })
            .catch(error =>{
                setTimeout(() => { this.$store.commit('SET_LOADER', false) }, 800),
                window.console.log('Logout error')
                window.console.log(error)

            })
        },

      
    },

    created() {
      //window.console.log("Store User Settings:")
      //window.console.log(this.$store.getters.user)

      //window.console.log("Local Storage User settings:")
      //window.console.log(getUserSettingsFromStorage(this.$store.getters.app.appKey))

      //window.console.log(this.$route.name)
    }
}
</script>

<style scoped>
  #toolbar-title {
    cursor: pointer !important;
  }

  .btn_toolbar_link {
    text-transform: capitalize;
  }
</style>